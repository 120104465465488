/**
 * Created by chandru on 12/11/18.
 */

import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ConfigService} from './config.service';
import moment from 'moment-timezone';
import {forEach, uniq} from 'lodash';

@Injectable()
export class DateFormatterService {
  constructor(private http: HttpClient, public configService: ConfigService) {
  }

  private userTimezone: Timezone;

  getUserTimezone() {
    return new Promise<Timezone>((resolve) => {
      if (!this.userTimezone && !!sessionStorage.getItem('token')) {
        const subscribe = {
          next: (timezone: Timezone) => {
            this.setUserTimezone(timezone);
            sessionStorage.setItem('timezone', timezone.timezone);
            resolve(this.userTimezone);
          },
          error: console.error,
          complete: console.log
        }
        this.getTimezone().subscribe(subscribe);
      } else {
        resolve(this.userTimezone);
      }
    });
  }

  getTimezone() {
    return this.http.get<Timezone>(`${this.configService.appConfig.appBaseUrl}user/self/timezone`);
  }

  setUserTimezone(timezone: Timezone) {
    this.userTimezone = timezone;
  }

  resetUserTimezone() {
    this.userTimezone = null;
  }

  convertDateToSelectedTimezone(date, dateFormat?) {
    if (!date) {
      return "";
    }
    let timezone: string;
    if (this.userTimezone && this.userTimezone.timezone) {
      timezone = this.userTimezone.timezone;
    } else if (sessionStorage.getItem('timezone')) {
      timezone = sessionStorage.getItem('timezone');
    } else {
      this.getTimezone().subscribe((res: any) => {
        timezone = res?.timezone;
        this.setUserTimezone(res);
      });
    }

    if (dateFormat) {
      const ans = moment(date)?.tz(timezone);
      return ans?.format(dateFormat?.toUpperCase());
    } else {
      return moment(date)?.tz(timezone)?.format('LLL');
    }
  }

  getMomentInSelectedTimezone(date, dateFormat?) {
    let timezone: string;
    if (this.userTimezone && this.userTimezone.timezone) {
      timezone = this.userTimezone.timezone;
    } else if (sessionStorage.getItem('timezone')) {
      timezone = sessionStorage.getItem('timezone');
    } else {
      this.getTimezone().subscribe((res: any) => {
        timezone = res.timezone;
        this.setUserTimezone(res);
      },);
    }
    if (dateFormat) {
      return moment(date).tz(timezone).format(dateFormat);
    } else {
      return moment(date).tz(timezone);
    }
  }

  getFormattedTimeSlot(slot) {
    let finalTime = ``;
    if (slot) {
      const aTimeS = slot.split('-');
      const cTimes = [];
      let zValue = [];
      forEach(aTimeS, timeS => {
        timeS = timeS.trim();
        const aTimes = timeS.split(' ');
        if (aTimes && aTimes.length > 1 && aTimes[0] && aTimes[1]) {
          const time = aTimes[0];
          const z = aTimes[1].slice(0, 1).toLowerCase();
          zValue.push(z);
          cTimes.push(time);
        }
      })
      if (cTimes && cTimes.length === 2 && cTimes[0] && cTimes[1]) {
        const time1 = cTimes[0];
        const time2 = cTimes[1];
        zValue = uniq(zValue);
        if (zValue && zValue.length === 2) {
          finalTime = `${time1} ${zValue[0]} - ${time2} ${zValue[1]}`;
        } else if (zValue && zValue.length === 1) {
          finalTime = `${time1} - ${time2} ${zValue[0]}`;
        } else {
          finalTime = `${time1} - ${time2}`;
        }
      }
    }
    return finalTime;
  }

  getFormattedTimeSlotForSingleTime(date, timeFormat?) {
    const slot = this.convertDateToSelectedTimezone(date, timeFormat ?? 'LT');
    let finalTime = ``;
    let time = '';
    let zValue = '';
    const aTimes = slot?.split(' ');
    if (aTimes && aTimes.length > 1 && aTimes[0] && aTimes[1]) {
      time = aTimes[0];
      zValue = aTimes[1].slice(0, 1).toLowerCase();
    }
    finalTime = `${time} ${zValue}`;
    return finalTime;
  }

  getDateComponents(dateString) {

    //Input:- 2024-11-09T18:30:00.000Z
    //Output:- {"day": 5,"month": "Dec","year": 2024,"hours": "00","minutes": "00","dayOfWeek": "Thursday"}

    const inputDate = new Date(dateString);

    const day = inputDate.getDate();
    const month = new Intl.DateTimeFormat('en-IN', { month: 'short' }).format(inputDate);
    const year = inputDate.getFullYear();

    const dayOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"][inputDate.getDay()];

    const hours = inputDate.getHours().toString().padStart(2, '0');
    const minutes = inputDate.getMinutes().toString().padStart(2, '0');

    return {day, month, year, hours, minutes,dayOfWeek};
  }

  getTimeDifferenceFromString(startDateString, endDateString, returnJustDiff=false) {
    const startDate = new Date(startDateString);
    const endDate = new Date(endDateString);
    const timeDifferenceInMilliseconds =  startDate.getTime() - endDate.getTime() ;
    const minutes = Math.floor(timeDifferenceInMilliseconds / (1000 * 60));
    const hours = Math.floor(timeDifferenceInMilliseconds / (1000 * 60 * 60));
    const days = Math.floor(timeDifferenceInMilliseconds / (1000 * 60 * 60 * 24));

    let timePassed;
    const endTime = this.getFormattedTimeSlotForSingleTime(endDate)

    if (minutes < 60) {
        timePassed = `${minutes} min${minutes !== 1 ? 's' : ''}`;
    } else if (hours < 24) {
        timePassed = `${hours} hr${hours !== 1 ? 's' : ''}`;
        const mins = minutes%60
        if(mins){
          timePassed += `, ${mins} min${mins !== 1 ? 's' : ''}`;
        }
    } else {
        timePassed = `${days} day${days !== 1 ? 's' : ''}`;
    }
    if(returnJustDiff){
      return timePassed
    } else {
      return {timePassed , endTime}
    }
}

  checkIfDateIsToday(date) {
    let res = false;
    // Create date from input value
    const inputDate = new Date(date);

    // Get today's date
    const todaysDate = new Date();

    // call setHours to take the time out of the comparison
    if (inputDate.setHours(0, 0, 0, 0) == todaysDate.setHours(0, 0, 0, 0)) {
      // Date equals today's date
      res = true;
    }
    return res;
  }

     convertTo12HourFormat(time) {
      //Input:-  15:22 
      //Output:-  3:22 PM

    let [hours, minutes] = time.split(':').map(Number);
    const period = hours >= 12 ? 'PM' : 'AM';

    hours = hours % 12 || 12;
    return `${hours}:${String(minutes).padStart(2, '0')} ${period}`;
}





  
}

export interface Timezone {
  timezone: string;
}
