import {AppState} from '../app.state';
import {createSelector} from '@ngrx/store';
import {AssetsState} from './assets.reducer';

const assets = (state: AppState) => state.assets;

export const hasLoadedSelector = createSelector(
  assets, (state: AssetsState) => state.hasLoaded
);
export const assetConfigSelector = createSelector(
  //  assets, (state: AssetsState, type: string) => state.assetConfig.find(a => a.type === type)
  assets, (state: AssetsState) => state.assetConfig
);
export const individualAssetConfigSelector = ({assetType}) => createSelector(
  assets, (state: AssetsState) => state.assetConfig.find(a => a.type === assetType)
)
export const assetCityLabelSelector = createSelector(
  assets, (state: AssetsState) => state.city
);
export const assetLogisticProviderLabelSelector = createSelector(
  assets, (state: AssetsState) => state.logisticProvider
);
export const timeSlotsSelector = createSelector(
  assets, (state: AssetsState) => state.timeSlots
);
export const productsSelector = createSelector(
  assets, (state: AssetsState) => state.product
);
export const vehiclesSelector = createSelector(
  assets, (state: AssetsState) => state.vehicle
);
export const mobileSelector = createSelector(
  assets, (state: AssetsState) => state.mobile
);
export const mmdCitiesSelector = createSelector(
  assets, (state: AssetsState) => state.mmdCities
);
export const lmdCitiesSelector = createSelector(
  assets, (state: AssetsState) => state.lmdCities
);
export const deliveryCentersSelector = createSelector(
  assets, (state: AssetsState) => state.deliveryCenter
);
export const locationsSelector = createSelector(
  assets, (state: AssetsState) => state.location
);
export const tableHeadersO2DOrderSelector = createSelector(
  assets, (state: AssetsState) => state.tableHeaderO2DOrders
);
export const tableHeadersO2DPending = createSelector(
  assets, (state: AssetsState) => state.tableHeaderO2DPending
);
export const tableHeadersO2DProcessingSelector = createSelector(
  assets, (state: AssetsState) => state.tableHeaderO2DProcessing
);
export const tableHeadersO2DDispatchSelector = createSelector(
  assets, (state: AssetsState) => state.tableHeaderO2DDispatch
);
export const tableHeadersO2DProgressSelector = createSelector(
  assets, (state: AssetsState) => state.tableHeaderO2DProgress
);
export const tableHeadersMMDConsignmentSelector = createSelector(
  assets, (state: AssetsState) => state.mmdConsignmentTableHeaderData
);
export const tableHeadersMMDConsignmentQueueSelector = createSelector(
  assets, (state: AssetsState) => state.mmdConsignmentQueueTableHeaderData
);
export const tableHeadersMMDConsignmentAssignSelector = createSelector(
  assets, (state: AssetsState) => state.mmdConsignmentAssignTableHeaderData
);
export const tableHeadersMMDVehicleAssignSelector = createSelector(
  assets, (state: AssetsState) => state.mmdVehicleAssignTableHeaderData
);
export const tableHeadersMMDVehicleQueueSelector = createSelector(
  assets, (state: AssetsState) => state.mmdVehicleQueueTableHeaderData
);
export const tableHeadersMMDManagementSelector = createSelector(
  assets, (state: AssetsState) => state.mmdManagementTableHeaderData
);
export const tableHeadersLMDOrderSelector = createSelector(
  assets, (state: AssetsState) => state.lmdOrderTableHeaderData
);
export const tableHeadersLMDOrderQueueSelector = createSelector(
  assets, (state: AssetsState) => state.lmdOrderQueueTableHeaderData
);
export const tableHeadersLMDOrderAssignSelector = createSelector(
  assets, (state: AssetsState) => state.lmdOrderAssignTableHeaderData
);
export const tableHeadersLMDAgentAssignSelector = createSelector(
  assets, (state: AssetsState) => state.lmdAgentAssignTableHeaderData
);
export const tableHeadersLMDManagementSelector = createSelector(
  assets, (state: AssetsState) => state.lmdManagementTableHeaderData
);
export const deliveriesDataSelector = createSelector(
  assets, (state: AssetsState) => state.deliveriesData
);
export const carrierDataSelector = createSelector(
  assets, (state: AssetsState) => state.carrierData
);
export const expansionRowTableHeadingsSelector = createSelector(
  assets, (state: AssetsState) => state.expansionRowTableHeadings
)
export const deliveriesCountSelector = createSelector(
  assets, (state: AssetsState) => state.counts?.['deliveriesCount']
)
export const carriersCountSelector = createSelector(
  assets, (state: AssetsState) => state.counts?.['carriersCount']?.count
)
export const assetsCountSelector = createSelector(
  assets, (state: AssetsState) => state.counts
)
export const selectedAssetsDataByAssetTypeSelector = ({assetType}) => createSelector(
  assets, (state: AssetsState) => state[assetType]
)
export const selectedAssetsDataSelector = createSelector(
  assets, (state: AssetsState) => state.selectedAssetData
)
export const selectedAssetsTypeSelector = createSelector(
  assets, (state: AssetsState) => state.selectedAssetType
)
export const assetDefaultImageConfigsSelector = createSelector(
  assets, (state: AssetsState) => state.assetDefaultImageConfigs
)
export const categoriesSelector = createSelector(
  assets, (state: AssetsState) => state.categories
)
export const categoryReorderedSelector = createSelector(
  assets, (state: AssetsState) => state.categoryReordered
)
export const selectedAssetLinkedGroupsSelector = ({assetType}) => createSelector(
  assets, (state: AssetsState) => state.assetLinkedGroups?.[assetType]
)


export const kioskOrderTableHeadersSelector = createSelector(
  assets, (state: AssetsState) => state.kioskOrderTableHeaderData
);

export const kioskTodayOrderTableHeadersSelector = createSelector(
  assets, (state: AssetsState) => state.kioskTodayOrderTableHeaderData
);

export const kioskCDSTableHeadersSelector = createSelector(
  assets, (state: AssetsState) => state.kioskCDSTableHeaderData
);

export const kioskKDSTableHeadersSelector = createSelector(
  assets, (state: AssetsState) => state.kioskKDSTableHeaderData
);
export const isMobileViewSelector = createSelector(
  assets, (state: AssetsState) => state.isMobileView
)

export const kioskProductsTableHeadersSelector = createSelector(
  assets, (state: AssetsState) => state.kioskProductsTableHeaderData
);

export const individualLinkedAssetsSelector = createSelector(
  assets, (state: AssetsState) => state.linkedAssets
)

export const mmdRoutePlanTableHeaderSelector = createSelector(
  assets, (state: AssetsState) => state.routePlanTableHeader
)

export const mmdAllSchedulesTableHeaderSelector = createSelector(
  assets, (state: AssetsState) => state.allScheduleTableHeader
)

export const mmdTodaySchedulesTableHeaderSelector = createSelector(
  assets, (state: AssetsState) => state.todayScheduleTableHeader
)

export const routePlanCountSelector = createSelector(
  assets, (state: AssetsState) => state.routePlanCount
)

export const routePlansSelector = createSelector(
  assets, (state: AssetsState) => state.routePlans
)

export const geoFencesForAssetSelector = createSelector(
  assets, (state: AssetsState) => state.geoFenceForAssetData
)

export const getO2DOrders = (key: string) => createSelector(
  assets, (state: AssetsState) => {
    return state.tableBody[key]
  }
)

export const assetsCardsImageDisplayStatusSelector = createSelector(
  assets, (state: AssetsState) => state.assetCardsImageDisplayStatus
)

export const groupedRoutePlansArraySelector = createSelector(
  assets, (state: AssetsState) => state.expansionRowTableHeadings?.routePlans?.groupRoutePlanArray || []
)

export const communicationTableHeaderSelector = createSelector(
  assets, (state: AssetsState) => state.communicationTableHeader
)

export const getKioskMenu = createSelector(
  assets, (state: AssetsState) => state.menu
)

export const getCurrentPageData = createSelector(
  assets, (state: AssetsState) => state.currentPageNumber
)

export const warehouseRequisitionTableHeadersSelector = createSelector(
  assets, (state: AssetsState) => state.warehouseRequisitionTableHeader
);

export const warehouseFulfillmentTableHeadersSelector = createSelector(
  assets, (state: AssetsState) => state.warehouseFulfillmentTableHeader
);

export const warehouseSupplyTableHeadersSelector = createSelector(
  assets, (state: AssetsState) => state.warehouseSupplyTableHeader
);

export const warehouseProgressTableHeadersSelector = createSelector(
  assets, (state: AssetsState) => state.warehouseProgressTableHeader
);

export const selectMultipleAssetCreationStatus = createSelector(
  assets, (state: AssetsState) => state.multipleAssetCreationStatus
);



export const selectAllSchedules = createSelector(
  assets, (state: AssetsState) => state.allSchedules
);

export const selectTodaySchedules = createSelector(
  assets, (state: AssetsState) => state.todaySchedules
);

export const selectConflictingSchedules = createSelector(
  assets, (state: AssetsState) => state.conflictingSchedules
)